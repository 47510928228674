import React, { useState } from "react"
import ReactDom from "react-dom/client"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import CookieConsent from "react-cookie-consent"
import TimeArea from "./Components/TimeArea"

import Header from "./Components/Header"
import HomeGuest from "./Components/HomeGuest"
import Home from "./Components/Home"
import Footer from "./Components/Footer"
import About from "./Components/About"
import Terms from "./Components/Terms"
import Impressum from "./Components/Impressum"

function Main() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/impressum" element={<Impressum />} />
      </Routes>

      <TimeArea />
      <Footer />
      <CookieConsent debug={true} location="bottom" cookieName="myAwesomeCookies" expires={999} buttonText="Ich bin einverstanden" overlay>
        Diese Seite nutzt Cookies.
      </CookieConsent>
    </BrowserRouter>
  )
}

export default Main
