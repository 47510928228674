import React, { useState } from "react"

function Change() {
  const [farbe, setFarbe] = useState()

  function farbeGruen() {
    setFarbe(function (prev1) {
      return (prev1 = "Gruen")
    })
  }

  function farbeBlau() {
    setFarbe(function (prev1) {
      return (prev1 = "Blau")
    })
  }

  return (
    <>
      <button onClick={farbeGruen}>Gruen</button>
      <button onClick={farbeBlau}>Blau</button>
      <h2>This page has been liked {farbe} times.</h2>
    </>
  )
}

export default Change
