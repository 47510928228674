import React, { useState } from "react"
import Change from "./Change"
import Formular from "./Formular"
import Page from "./Page"

function Home() {
  const [likeCount, setLikeCount] = useState(0)

  function increaseLikeHandler() {
    setLikeCount(function (prev) {
      return prev + 1
    })
  }

  function decreaseLikeHandler() {
    setLikeCount((prev) => {
      if (prev > 0) {
        return prev - 1
      }
      return 0
    })
  }
  return (
    <Page title="Your Feed">
      <h2 className="text-center">
        Hallo <strong>{localStorage.getItem("complexappUsername")}</strong>
      </h2>
      <button onClick={increaseLikeHandler}>Increase likes</button>
      <button onClick={decreaseLikeHandler}>Decrease likes</button>
      <h2>Zähler: {likeCount}</h2>
      <Change />
      <Formular />
      <p className="lead text-muted text-center"></p>
    </Page>
  )
}

export default Home
