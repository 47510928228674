import React, { useEffect } from "react"
import Container from "./Container"

export default function Page(props) {
  // useEffect(a, b)
  // a = Eine Funktion die zu einer bestimmten Zeit läuft
  // b = Abhängigkeit And this is how you tell React only run this the first time the component is rendered
  //useEffect(a, [])

  useEffect(() => {
    document.title = `${props.title} | TGruWebdesign`
    window.scrollTo(0, 0)
  }, [])

  return <Container wide={props.wide}>{props.children}</Container>
}
