import React, { useEffect, useState } from "react"

function EingabeForm(werteformfelder) {
  const [name, setName] = useState()
  const [species, setSpecies] = useState()
  const [age, setAge] = useState()

  function handleSubmit(e) {
    e.preventDefault()
    werteformfelder.setPets((vorher) => vorher.concat({ name, species, age, id: Date.now() }))
    //wird wieder geleert
    setName("")
    setSpecies("")
    setAge("")
  }

  return (
    <form onSubmit={handleSubmit}>
      <fieldset>
        <legend>Add New Pet</legend>
        <input required={true} minLength={1} maxLength={5} value={name} onChange={(e) => setName(e.target.value)} placeholder="Name" />
        <input required={true} minLength={1} maxLength={5} value={species} onChange={(e) => setSpecies(e.target.value)} placeholder="species" />
        <input required={true} minLength={1} maxLength={2} value={age} onChange={(e) => setAge(e.target.value)} placeholder="age in years" />
        <button>Add</button>
      </fieldset>
    </form>
  )
}

function WiesollichesAusgeben(props) {
  function handleDelete() {
    props.setPets((prev1) => prev1.filter((ausgabewerte1) => ausgabewerte1.id != props.id))
  }

  return (
    <li>
      {props.name} is a {props.species} and is {props.age} years old.
      <button onClick={handleDelete}> Delete</button>
    </li>
  )
}

function Formular() {
  const [tiere, setPets] = useState([])

  // only run once the first time this component ist rendered
  useEffect(() => {
    if (localStorage.getItem("examplePetData")) {
      setPets(JSON.parse(localStorage.getItem("examplePetData")))
    }
  }, [])
  // run every time our pet state changes
  useEffect(() => {
    localStorage.setItem("examplePetData", JSON.stringify(tiere))
  }, [tiere])
  return (
    <>
      <EingabeForm setPets={setPets} />
      <ul>
        {tiere.map((ausgabewerte) => (
          <WiesollichesAusgeben setPets={setPets} id={ausgabewerte.id} name={ausgabewerte.name} species={ausgabewerte.species} age={ausgabewerte.age} key={ausgabewerte.id} />
        ))}
      </ul>
    </>
  )
}

export default Formular
